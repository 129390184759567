import React, { useState, useEffect } from 'react';

function Ticker({ textItems }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 2) % textItems.length);
    }, 2000); // Change the interval duration to 2 seconds (2000ms)

    return () => clearInterval(intervalId);
  }, [textItems]);

  return (
    <div className="ticker">
      {textItems[currentIndex]}
    </div>
  );
}

export default Ticker;