import React, { useState, useEffect } from 'react'
import {
  formatDistance,
  addDays,
  addHours,
  addMinutes,
  addSeconds
} from 'date-fns'
import Ticker from './ticker' // Import the Ticker component
import ReactTypingEffect from 'react-typing-effect'
import juliobg from './julio.jpeg'
import jarredbg from './bg.png'
import trophy from './trophy.png'
const FadingComponent = ({ children, isVisible }) => {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    if (isVisible) {
      // If the component is visible, fade it in
      setOpacity(1)
    } else {
      // If the component is not visible, fade it out
      setOpacity(0)
    }
  }, [isVisible])

  return (
    <div
      style={{
        opacity,
        transition: 'opacity 1 ease-in-out',
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        backgroundColor: '#13274F'
      }}
    >
      {children}
    </div>
  )
}
function CountdownTimer () {
  // Set the target date to September 11, 2023, at 00:00:00
  const targetDate = new Date('2023-09-11T00:00:00')

  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)

  const [dark, setDark] = useState(params.get('dark') === 'true')
  const [stats, setStats] = useState([])

  const [julio, setJulio] = useState(params.get('player') === 'juliorodriguez')
  function getCurrentDate () {
    const today = new Date()

    // Get the day, month, and year components
    const day = today.getDate().toString().padStart(2, '0')
    const month = (today.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed, so we add 1.
    const year = today.getFullYear()

    // Combine them in the desired format
    const formattedDate = `${month}/${day}/${year}`

    return formattedDate
  }
  const currentDate = getCurrentDate()
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date()
      const timeRemaining = targetDate - currentDate

      if (timeRemaining <= 0) {
        clearInterval(intervalId)
        // Handle the event when the countdown is complete
      } else {
        const remainingDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
        const remainingHours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const remainingMinutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        )
        const remainingSeconds = Math.floor(
          (timeRemaining % (1000 * 60)) / 1000
        )
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://www.fangraphs.com/api/players/game-log?playerid=22558&position=OF&type=-40&season=&gds=&gde=&st=true&&z=1709909959'
        )

        const data = await response.json()
        // Assuming that 'mlb' property is present in the response object
        const mlbProperty = data?.mlb
        console.log(mlbProperty)

        setStats(mlbProperty)
      } catch (error) {
        console.error('Error fetching data:', error)
        // You may want to handle the error in a more user-friendly way
      }
    }

    fetchData()
  }, [])
  const [currentIndex, setCurrentIndex] = useState(0)

  if (stats.length) {
    console.log(
      Object.entries(stats[0])
        .map(([key, value]) => `${key}: ${value}`)
        .filter(
          text =>
            text.includes('3B: ') ||
            text.includes('H: ') ||
            text.includes('RBI: ') ||
            text.includes('BB: ') ||
            text.includes('HR: ') ||
            text.includes('1B: ') ||
            text.includes('2B: ') ||
            text.includes('Spd: ')
        )
        .filter(text => !text.includes(': 0') || text.includes('Spd '))
        .map(text => text.replace('H: ', 'Hits: ').replace('BB: ', 'Walks: '))
    )
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Show the next component
      setCurrentIndex(prevIndex => (prevIndex + 1) % 5)
    }, 5000) // 2-second interval

    // Clear the interval on component unmount
    return () => clearInterval(intervalId)
  }, []) // Empty dependency array ensures the effect runs only once
  return (
    <div id='main'>
      <div id='overlay'>
        {' '}
        <div class='pyro'>
          <div class='before'></div>
          <div class='after'></div>
        </div>
      </div>

      <img
        src={jarredbg}
        style={{ visibility: 'hidden', width: '0px', height: '0px' }}
        alt='Logo'
      />
      <div class='countdown'>
        <p class='date '>Jarred Kelenic</p>

        {julio ? (
          <p class='stats'>
            Last Updated: {currentDate}
            <p class='stats season'>
              .289/.343/.504 ✵ 134 wRC+ ✵ 5.9fWAR/5.5bWAR
            </p>
            <ReactTypingEffect
              typingDelay={50}
              speed={110}
              eraseDelay={1800}
              cursor={' '}
              eraseSpeed={0}
              text={[
                'Today(LAA@SEA): 1:10PM PST, Batting 2nd',
                'Yesterday: 2 for 4, 1RBI',
                'Last 50PA: .267xBA ✵.367 xwOBA ✵ 18% K%'
              ]}
            />
          </p>
        ) : (
          <p class='stats'>
            <span style={{ fontStyle: 'italic' }}>
              LAST UPDATED: {currentDate}
            </span>{' '}
            <br />
            {true && (
              <ReactTypingEffect
                typingDelay={50}
                speed={90}
                eraseDelay={1800}
                cursor={' '}
                eraseSpeed={0}
                text={[
                  'ADJUSTED Batting Average: .293',
                  'Arm Value: 98th Percentile',
                  'Sweet Spot%: 90th Percentile',
                  'Hard Hit Rate: 46.3%'
                ]}
              />
            )}
          </p>
        )}
        <div class='trophy-case'>
          <FadingComponent key={0} isVisible={0 === currentIndex}>
            {' '}
            <img src={trophy} /> USA TEAM MVP (2016, U-18)
          </FadingComponent>

          <FadingComponent key={2} isVisible={2 === currentIndex}>
            {' '}
            <img src={trophy} /> #3 OVERALL MLB PROSPECT
          </FadingComponent>
          <FadingComponent key={3} isVisible={3 === currentIndex}>
            {' '}
            <img src={trophy} /> ALLTIME RAINIERS OPS LEADER
          </FadingComponent>
          <FadingComponent key={1} isVisible={1 === currentIndex}>
            {' '}
            <img src={trophy} /> 2X USA GOLD MEDALIST
          </FadingComponent>
          <FadingComponent key={4} isVisible={4 === currentIndex}>
            {' '}
            <img src={trophy} /> FIRST ROUND DRAFT PICK
          </FadingComponent>
        </div>
      </div>
    </div>
  )
}

export default CountdownTimer
